.scraped_event_card {
  padding: 16px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 1;
}

.scraped_event_card.card {
  max-width: 280px;
  min-width: 280px;
}

.image-container {
  width: 100%;
  height: 160px;
  max-height: 160px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

.scraped_event_img {
  object-fit: cover;
}

.image-container .event_status_tag {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 20;
  padding: 6px 8px;
  border-radius: 4px;
}

.accepted {
  background-color: #4caf50 !important;
}

.pending {
  background-color: #878d1d !important;
}

.rejected {
  background-color: #d60446 !important;
}

.event_status_tag p {
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  margin: 0;
  text-transform: capitalize;
}

.event-title-container {
  padding: 4px;
  margin-top: 8px;
}

.event-title-container p {
  font-size: 18px;
  font-weight: 500;
  color: #1e1e1e;
}

.location-container {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.location-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 8px;
  margin-left: 4px;
}

.location-box-decorator {
  height: 40px;
  width: 4px;
  border-radius: 40px;
  background-color: #428cfc;
}

.location-container p {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  margin: 0;
  margin-left: 4px;
}

.location-container p:nth-child(2) {
  font-size: 12px;
  color: #428cfc;
  font-weight: 400;
}

.scraped_event_card.list {
  display: flex;
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  margin-bottom: 16px;
  transition: all 0.2s ease;
  border: 1px solid #f0f0f0;
  position: relative;
}

.scraped_event_card.list:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
}

.scraped_event_card.list[data-show-actions='true'] {
  z-index: 9999;
}

.scraped_event_card.list .image-container {
  width: 100px;
  min-width: 100px;
  height: 100px;
  margin-right: 16px;
  border-radius: 10px;
  overflow: hidden;
}

.scraped_event_card.list .event-title-container {
  flex: 1;
  margin-top: 0;
  padding: 0;
}

.scraped_event_card.list .event-title-container p {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 8px 0;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #333;
}

.scraped_event_card.list .location-container {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.scraped_event_card.list .location-box-decorator {
  height: 36px;
  width: 3px;
  margin-right: 10px;
  border-radius: 3px;
}

.scraped_event_card.list .location-col {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-left: 0;
}

.scraped_event_card.list .location-col p {
  margin: 0;
  font-size: 13px;
  line-height: 1.2;
}

.scraped_event_card.list .location-col p:first-child {
  font-weight: 500;
  color: #555;
}

.scraped_event_card.list .location-col p:last-child {
  font-weight: 400;
  color: #428cfc;
}

.scraped_event_card.list .event_status_tag {
  top: 6px;
  right: 6px;
  padding: 4px 8px;
  background-color: rgba(245, 225, 47, 0.9);
  border-radius: 4px;
}

.scraped_event_card.list .event_status_tag p {
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Estilos para el content-wrapper */
.content-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.scraped_event_card.list .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100px;
  padding: 4px 0;
}

/* Estilos para las tags */
.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
}

.card-tags {
  padding: 0 4px;
}

.event-tag {
  display: inline-block;
  padding: 4px 10px;
  background-color: #f0f4ff;
  color: #428cfc;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
}

/* Ajustes para el modo lista */
.scraped_event_card.list .tags-container {
  margin-top: 8px;
}

.scraped_event_card.list .event_status_tag {
  top: 6px;
  right: 6px;
  padding: 4px 8px;
  background-color: rgba(245, 225, 47, 0.9);
  border-radius: 4px;
  z-index: 30;
}

.scraped_event_card.list .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100px;
  padding: 4px 0;
}

/* Estilos para el botón de acciones y el menú */
.actions-container {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 9999;
}

.actions-container.card {
  top: 16px;
  right: 16px;
}

.actions-container.list {
  top: 12px;
  right: 12px;
}

.actions-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.scraped_event_card.card .actions-button {
  background-color: transparent;
}

.actions-button:hover {
  transform: scale(1.05);
}

.scraped_event_card.card .actions-button:hover {
  background-color: transparent;
}

.actions-menu {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  padding: 8px;
  min-width: 160px;
  z-index: 9999;
}

.scraped_event_card.card .actions-menu {
  top: 48px;
}

.scraped_event_card.list .actions-menu {
  top: 44px;
}

.action-item {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 12px;
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.action-item:hover {
  background-color: #f5f5f5;
}

.action-item span {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.action-item.accept {
  color: #4caf50;
}

.action-item.delete {
  color: #f44336;
}

.action-item.edit {
  color: #428cfc;
}
